import { createApp } from "vue";
import router from "./router/index.js";
import App from "./App.vue";
import axios from "axios";
import "bulma/css/bulma.css";
import "./styles/site.css";
import { createPinia } from "pinia";
import ChatDisplay from "./components/ChatDisplay.vue";

let API_ORIGIN = "http://127.0.0.1:8000/api/v1/";
let WS_ORIGIN = "ws://127.0.0.1:8000/ws/";
if (process.env.NODE_ENV === "production") {
  API_ORIGIN = "https://www.botenn.com/api/v1/";
  WS_ORIGIN = "wss://botenn.com/ws/";
}

axios.defaults.baseURL = API_ORIGIN;
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json";

function getCookie(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
}

function userIsAuthenticated() {
  return getCookie("csrftoken") !== null;
}

axios.interceptors.request.use(
  function (config) {
    config.headers["X-CSRFToken"] = getCookie("csrftoken");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      router.replace("/login");
      alert("You have been logged out due to inactivity.");
    }
    return Promise.reject(error);
  }
);

const pinia = createPinia();
const app = createApp(App);

app.config.globalProperties.$api = axios;
app.config.globalProperties.$ws_origin = WS_ORIGIN;
app.config.globalProperties.$auth = {
  isAuthenticated: userIsAuthenticated,
};

app.component("ChatDisplay", ChatDisplay);

app.use(pinia);
app.use(router);
app.mount("#app");
