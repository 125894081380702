<script>
import { useAuthenticationStore } from '@/stores/authentication';

export default {
    data() {
        return {
            username: '',
            email: '',
            password1: '',
            password2: '',
            pending_registration: false,
        };
    },
    setup() {
        const authStore = useAuthenticationStore();
        return { authStore };
    },
    methods: {
        register() {
            var self = this;
            self.pending_registration = true;
            if (this.password1 !== this.password2) {
                console.error('passwords do not match');
                return;
            }
            this.$api.post('register/', {
                username: this.username,
                email: this.email,
                password: this.password1,
            })
                .then(() => {
                    self.authStore.login();
                    this.$router.push('/');
                })
                .catch(error => {
                    self.authStore.logout();
                    console.error(error);
                })
                .finally(() => {
                    self.pending_registration = false;
                })
                ;
        }
    }
}
</script>

<template>
    <div class="section">
        <div class="columns is-centered">
            <div class="column is-4">
                <h2 class="title"><strong>register</strong></h2>
                <form @submit.prevent="register">
                    <label class="label">username</label>
                    <input class="input mb-1" v-model="username"
                        placeholder="username" type="text">
                    <label class="label">e-mail</label>
                    <input class="input mb-1" v-model="email"
                        placeholder="e-mail" type="email">
                    <label class="label">password</label>
                    <input class="input mb-1" v-model="password1"
                        placeholder="password" type="password">
                    <label class="label">confirm password</label>
                    <input class="input" v-model="password2"
                        placeholder="password" type="password">
                    <div class="buttons is-right mt-2">
                        <button 
                            class="button" 
                            type="submit"
                            :class="{ 'is-loading': pending_registration === 'true' }"
                            >
                            register
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
