<script>
import { useAuthenticationStore } from '@/stores/authentication';

export default {
    data() {
        return {
            username: 'but not logged in',
            bio: '',
        }
    },
    setup() {
        const authStore = useAuthenticationStore();
        return { authStore };
    },
    watch: {
        'authStore.isLoggedIn'() {
            this.getProfile();
        }
    },
    mounted () {
        if (this.authStore.isLoggedIn) {
            this.getProfile();
        }
    },
    methods: {
        getProfile() {
            var self = this;
            this.$api.get('profile/')
                .then(response => {
                    console.log(response)
                    self.bio = response.data.bio;
                    self.username = response.data.username;
                })
                .catch(() => {
                    self.bio = '';
                    self.username = 'but not logged in ';
                });
        }
    },
}
</script>

<template>
    <div class="section">
        <h3 class="subtitle">home</h3>
        <span>you home {{ username }} </span>
        <p>{{ bio }}</p>

    </div>
</template>