<template>
  <link rel="shortcut icon" href="#" />
  <title>botenn</title>
  <div id="wrapper">
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">

        <a role="button" class="navbar-burger" aria-label="menu"
          aria-expanded="false" data-target="navbarBase">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBase" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item">
            <router-link to="/"
              class="navbar-item"><strong>home</strong></router-link>
          </a>
          <a class="navbar-item">
            <router-link to="/about"
              class="navbar-item"><strong>about</strong></router-link>
          </a>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons" v-if="!isLoggedIn">
              <router-link to="/register" class="button is-primary is-small">
                <strong>register</strong></router-link>
              <router-link to="/login" class="button is-primary is-small">
                <strong>login</strong></router-link>
            </div>
            <div class="buttons" v-else>
              <button class="button is-dark is-small"
                @click="logout">logout</button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="columns">
      <div class="column">
        <main>
          <RouterView />
        </main>
      </div>
      <div class="column is-4">
        <div class="section">
          <h3 class="subtitle">comms</h3>
          <ChatDisplay/>
        </div>
      </div>
    </div>


    <footer class="footer">
      <p class="has-text-centered">botenn {{ version }}</p>
    </footer>

  </div>
</template>

<script>
import { useAuthenticationStore } from '@/stores/authentication';

export default {
  data() {
    return {
      version: '- loading...',
      bio: "",
      logged_in: false,
    }
  },
  setup() {
    const authStore = useAuthenticationStore();
    return { authStore };
  },
  mounted() {
    this.fetchVersion();
    this.getProfile();
  },
  computed: {
    isLoggedIn() {
      return this.authStore.isLoggedIn;
    },
  },
  methods: {
    goToAbout() {
      this.$router.push('/about')
    },
    goToLogin() {
      this.$router.push('/login')
    },
    fetchVersion() {
      this.$api.get('version/')
        .then(response => {
          this.version = response.data;
        })
        .catch(() => {
          this.version = '- api connection issue';
        });
    },
    logout() {
      this.$api.post('logout/')
      .then(() => {
        this.authStore.logout();
        this.$router.push('/');
      })
      .catch(error => {
        console.error('error logging out:', error);
      });
    },
    getProfile() {
      this.$api.get('profile/')
      .then(() => {
        this.authStore.login();
      })
      .catch(() => {
        this.authStore.logout();
      });
    }
  },
}
</script>