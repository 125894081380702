<script>
export default {
    methods: {
        noop() {
        },
    },
}
</script>

<template>
    <div class="section">
        <h3 class="subtitle">about</h3>
        <p>
            i really dont know what im doing here
        </p>
    </div>
</template>