import { defineStore } from "pinia";

export const useAuthenticationStore = defineStore("authentication", {
  state: () => {
    return { isLoggedIn: false };
  },
  actions: {
    login() {
      this.isLoggedIn = true;
    },
    logout() {
      this.isLoggedIn = false;
    },
  },
});
