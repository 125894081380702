<template>
    <div>
        <div class="chat-window">
            <div v-for="(msg, index) in messages" :key="index">
                <span :title=msg.created_at><strong>{{ msg.username }}</strong>:
                    {{
                msg.message }}</span>
            </div>
        </div>
        <hr>
        <div class="field has-addons">
            <div class="control">
                <input class="input" type="text" v-model="message"
                    @keyup.enter="sendMessage">
            </div>
            <div class="control">
                <button class="button is-success"
                    @click="sendMessage">Send</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            socket: null,
            messages: [],
            message: "",
        };
    },
    created() {
        var self = this;
        this.connect();
        this.$api.get('message/')
            .then(response => {
                console.log(response)
                self.messages = response.data;
            })
            .catch(() => {
                self.bio = '';
                self.username = 'but not logged in ';
            });
    },
    updated (){
        this.updateScroll()
    },
    methods: {
        connect() {
            const ws_origin = this.$ws_origin;
            this.socket = new WebSocket(`${ws_origin}chat/`);

            this.socket.onmessage = (event) => {
                console.log(event)
                const message = JSON.parse(event.data);
                this.messages.push(message);
            };

            this.socket.onclose = (event) => {
                console.log('Socket closed', event);
            };

            this.socket.onerror = (error) => {
                console.error('WebSocket Error', error);
            };
        },
        sendMessage() {
            if (this.message) {
                let text = JSON.stringify({ 'text': this.message })
                this.socket.send(text);
                this.message = "";
            }
        },
        updateScroll() {
            const chatWindow = document.querySelector('.chat-window');
            setTimeout(() => {
                chatWindow.scrollTop = chatWindow.scrollHeight;
            }, 0);  // Delaying the scroll update to the next event loop cycle
        },
    },
    onDestroy() {
        this.socket.close();
    }
};
</script>
