<script>
import { useAuthenticationStore } from '@/stores/authentication';

export default {
    data() {
        return {
            username: '',
            password: '',
            pending_login: false,
        
        };
    },
    setup() {
        const authStore = useAuthenticationStore();
        return { authStore };
    },
    methods: {
        login() {
            var self = this;
            self.pending_login = true;
            this.$api.post('login/', {
                username: this.username,
                password: this.password,
            })
                .then(() => {
                    self.pending_login = false;
                    self.authStore.login();
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error(error);
                    self.authStore.logout();
                    self.pending_login = false;
                });
        
        }
    },
}
</script>

<template>
    <div class="section">
        <div class="columns is-centered">
            <div class="column is-4">
                <h2 class="title"><strong>login</strong></h2>
                <form @submit.prevent="login">
                    <label class="label">username</label>
                    <input class="input mb-1" v-model="username"
                        placeholder="username" type="text">
                    <label class="label">password</label>
                    <input class="input mb-1" v-model="password"
                        placeholder="password" type="password">
                    <div class="buttons is-right mt-2">
                        <button class="button" type="submit"
                            :class="{ 'is-loading': pending_login === 'true' }">
                            login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
